import { TABLET_WIDTH, DEFAULT_REFRESH_INTERVAL } from '../constants'
import { ExperimentFlag } from '../enums/ExperimentFlag'
import { Device, WindowSize } from '../helpers'
import type WebModel from '../models/WebModel'
import { hasOutstream } from '../outstream/hasOutstream'
import { hasNative } from '../native/hasNative'
import { Slot } from './Slot'
import { ITimeoutMap, Timeout } from './helpers/Timeout'
import { AdBoxConfig, hasAdBox } from './helpers/AdBox'
import { hasInView } from './helpers/InView'
import { hasIBV } from '../ibv/hasIBV'
import { IBVConfig } from '../ibv/IBVConfig'
import { hasInterscroller } from './helpers/Interscroller'
import { IInterscrollerConfig } from './helpers/Interscroller'

const timeouts: RecursivePartial<ITimeoutMap> = {
  desktop: {
    atf: 1300,
    lazy: 2300,
    ref: 1500
  },
  mobile: {
    atf: 1400,
    lazy: 2500,
    ref: 1300
  }
}

const MAX_CONTENT_OUSTREAM_WIDTH = 510

interface IContentSlotModel extends ISlotModel {
  selector: HTMLElement
}

@hasInterscroller
@hasIBV
@hasOutstream
@hasNative
@hasInView
@hasAdBox
export class Content extends Slot {
  static experimentalOffset
  slotName = 'content'
  adUnitId = Device.isMobileOrTablet ? `content_mobile` : `content_btf`
  selector: HTMLElement
  lazy = true
  refreshTime = DEFAULT_REFRESH_INTERVAL
  restrictRefreshSize = true
  adBoxConfig = new AdBoxConfig({
    heightPxToContain: 324,
    widthStyle: '100%'
  })
  interscrollerConfig: IInterscrollerConfig = {
    isSlotValid: () => this.number % 2 === 1
  }
  maxOutstreamWidth = MAX_CONTENT_OUSTREAM_WIDTH
  ibvConfig = new IBVConfig(this)

  constructor(slotModel: IContentSlotModel, model: WebModel) {
    super(slotModel, model)

    this.timeout = new Timeout(this, timeouts)

    this._sizes = Device.isMobileOrTablet
      ? this.getMobileSizes(this.model)
      : this.getDesktopSizes(this.model)

    // We want the experiment to be on a page-level, so we're using a Static property
    // on Content to store the experimentalValue, which is computed only once.
    if (Content.experimentalOffset === undefined) {
      const offsetExperimentFlag = Device.isMobileOrTablet
        ? ExperimentFlag.contentMobileOffsetMultiplier
        : ExperimentFlag.contentBtfOffsetMultiplier

      Content.experimentalOffset = parseFloat(
        model.experiment.activate(offsetExperimentFlag) as string
      )
    }

    if (Device.isMobileOrTablet) {
      // Lazy load all but first content ad
      this.lazy = this.number > 1 ? true : false
    }

    this.ibvConfig.ibvSizes = () => [
      [1, 1],
      [300, 250]
    ]
  }

  get offset(): number {
    const mobileOffsetMultiplier = 4.6
    const desktopOffsetMultiplier = 3.4
    let offsetMultiplier = Device.isMobileOrTablet
      ? mobileOffsetMultiplier
      : desktopOffsetMultiplier

    // use experimentalOffset if it is truthy.
    if (Content.experimentalOffset) {
      offsetMultiplier = Content.experimentalOffset
      this.setTargeting({ offsetMultiplier })

      return Math.floor(WindowSize.height * Content.experimentalOffset)
    }

    return WindowSize.height * offsetMultiplier
  }

  get id(): string {
    if (Device.isMobileOrTablet) {
      return this.number > 1
        ? `content_${this.number}_mobile`
        : `content_mobile`
    } else {
      return this.number > 1 ? `content_${this.number}_btf` : `content_btf`
    }
  }

  meetsOutstreamCriteria(): boolean {
    const contentDensityMap = {
      off: Infinity,
      light: 3,
      medium: 2,
      high: 1
    }

    const densitySetting = this.model.outstream_density
    const densityNumber = contentDensityMap[densitySetting] || 2

    // eslint-disable-next-line no-magic-numbers
    const runAllSlotsMax = Device.isMobileOrTablet ? 4 : 2
    if (
      this.model.optimize_short_form_content &&
      this.total() > runAllSlotsMax
    ) {
      return true
    }

    const number = this.number

    return number % densityNumber === 0
  }

  template(): string {
    return Device.isMobileOrTablet
      ? `
      <div class='remove_padding'>
          <div id="${this.wrapperId}" class="adunitwrapper content_mobile_wrapper" data-wrapper='${this.id}' data-nosnippet>
            <div id="${this.id}" class="content_mobile adunit">
            </div>
          </div>
      </div>
    `
      : `
        <div id="${this.wrapperId}" class="adunitwrapper content_btf_wrapper" data-wrapper='${this.id}' data-nosnippet>
          <div id="${this.id}" class="content_btf adunit">
          </div>
        </div>
    `
  }

  // These static variables help performance a lot when Dynamically inserting content slots.
  static _desktopSizes
  static _mobileSizes
  private getDesktopSizes(model: WebModel): AdSizes {
    if (Content._desktopSizes) {
      return Content._desktopSizes
    }
    const element = this.selector
    const parent = element && (element.parentNode as HTMLElement | null)
    const width = (parent && parent.offsetWidth) || 300
    const sizes: AdSizes = [[300, 250]]
    if (width >= TABLET_WIDTH) {
      sizes.push([728, 90])
    }
    if (width >= 336) {
      sizes.push([336, 280], [468, 60], [250, 250])
    }
    if (!model.disableContentBtfNative) {
      sizes.push('fluid')
    }

    Content._desktopSizes = sizes
    return sizes
  }

  private getMobileSizes(model: WebModel): AdSizes {
    if (Content._mobileSizes) {
      return Content._mobileSizes
    }
    const sizes: AdSizes = [
      [300, 250],
      [320, 50],
      [300, 50],
      [320, 100],
      [336, 280],
      [468, 60],
      [728, 90],
      'fluid'
    ]

    Content._mobileSizes = sizes
    return sizes
  }
}
